import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Container } from "../components/global/grid";
import { Main } from "../components/global/main";
import { eventName, sendEvent } from "../gtm";

export const Contact = () => {
  const { t } = useTranslation();

  useEffect(() => sendEvent(eventName.PAGE_VIEW.CONTACT), []);

  return (
    <Main verticalCentered centeredContent>
      <Container>
        <h1>{t("contact.seo.title")}</h1>
        <span>{t("contact.page.user", { name: "Koblos Gyula" })}</span>
      </Container>
    </Main>
  );
};
