import { useTheme } from "../../utils/theme-provider";

export function ThemePicker() {
  const { setTheme } = useTheme();

  const activeTheme = localStorage.getItem("premium-vite-ui-theme") || "system";

  return (
    <div>
      <button className={`${activeTheme === "dark" ? "active" : ""}`} onClick={() => setTheme("dark")}>
        dark
      </button>
      <button className={`${activeTheme === "light" ? "active" : ""}`} onClick={() => setTheme("light")}>
        light
      </button>
    </div>
  );
}
