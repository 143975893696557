import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Nav = () => {
  const isActive = ({ isActive }: { isActive: boolean }) => `link ${isActive ? "active" : ""}`;
  const { t } = useTranslation();

  return (
    <nav data-testid="menu">
      <NavLink className={isActive} to="/">
        {t("home.navLabel")}
      </NavLink>
      <NavLink className={isActive} to="/contact">
        {t("contact.navLabel")}
      </NavLink>
    </nav>
  );
};

export default Nav;
