import { useTranslation } from "react-i18next";
import { getStorage, setStorage } from "../../utils/storage";
import { LANGUAGES } from "../../constants";

const LanguagePicker = ({ testId }: { testId?: string }) => {
  const { i18n } = useTranslation();

  const onChangeLang = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const lang_code = event.target.value;
    i18n.changeLanguage(lang_code);
    setStorage("lang", lang_code, 18000000);
  };

  const storageLang = getStorage("lang");

  const dataTestId = testId ? `data-testid="test-${testId}"` : {};

  if (LANGUAGES.length < 1) return;

  return (
    <select {...dataTestId} style={{ width: 80 }} onChange={onChangeLang} defaultValue={storageLang.length ? storageLang : i18n.language}>
      {LANGUAGES.map(({ code, label }) => (
        <option key={code} value={code}>
          {label}
        </option>
      ))}
    </select>
  );
};

export default LanguagePicker;
