// Extend the Window interface to include dataLayer
declare global {
  interface Window {
    dataLayer?: { push: (event: string) => void };
  }
}

export const sendEvent = (event: string) => {
  if (typeof window !== "undefined" && window.dataLayer) {
    window.dataLayer.push(event);
  } else {
    console.info(`GTM: ${event} event not sent, dataLayer is not defined`);
  }
};

export const eventName = {
  PAGE_VIEW: {
    HOME: "home",
    ABOUT: "about",
    CONTACT: "contact",
  },
  USER_INTERACTION: "user_interaction",
  FORM_SUBMIT: "form_submit",
  BUTTON_CLICK: "button_click",
  CUSTOM_EVENT: "custom_event",
};
