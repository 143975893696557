import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Main } from "../components/global/main";
import { eventName, sendEvent } from "../gtm";

export const Home = () => {
  const { t } = useTranslation();

  useEffect(() => sendEvent(eventName.PAGE_VIEW.HOME), []);

  return (
    <Main verticalCentered centeredContent>
      <h1>{t("home.seo.title")}</h1>
    </Main>
  );
};
