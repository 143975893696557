import React from "react";
import classNames from "classnames";
import "./main.scss";

export type MainProps = {
  centeredContent?: boolean;
  verticalCentered?: boolean;
  children?: React.ReactNode;
  className?: string;
};

const Main: React.FC<MainProps> = props => {
  const { centeredContent, verticalCentered, className } = props;

  return (
    <main
      className={classNames(className, {
        "centered-content": centeredContent,
        "vertical-centered": verticalCentered,
      })}
    >
      {props.children}
    </main>
  );
};
export default Main;
