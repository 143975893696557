import i18n from "i18next";
import I18NextHttpBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { getStorage } from "./src/utils/storage";
import { fallbackLng } from "./src/constants";

const getCurrentHost = import.meta.env.MODE === "development" ? "http://localhost:5173" : "";
const storageLang = getStorage("lang") || i18n.language;
i18n
  .use(I18NextHttpBackend)
  .use(initReactI18next)
  .init({
    fallbackLng,
    lng: storageLang,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: `${getCurrentHost}/i18n/{{lng}}.json`,
    },
  });

export default i18n;
