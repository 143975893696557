import { Suspense, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "../utils/theme-provider";
import { RootLayout } from "../layouts";

const App = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const updateHtmlLang = (lng: string) => {
      document.documentElement.lang = lng;
    };
    updateHtmlLang(i18n.language);
    i18n.on("languageChanged", updateHtmlLang);
    return () => i18n.off("languageChanged", updateHtmlLang);
  }, [i18n]);

  return (
    <ThemeProvider defaultTheme="system" storageKey="premium-vite-ui-theme" defaultThemeName="premium">
      <Suspense fallback="loading">
        <RootLayout />
      </Suspense>
    </ThemeProvider>
  );
};

export default App;
