import classNames from "classnames";
import React, { type ReactNode } from "react";

export interface ContainerProps {
  removeContainer?: boolean;
  sectionClassName?: string;
  sectionBackgroundImage?: string;
  sectionImage?: React.ReactElement | string;
  fluid?: boolean;
  className?: string;
  children?: ReactNode;
  section?: boolean;
}
const Container = (props: ContainerProps) => {
  const { section = false, fluid = false, sectionClassName, sectionBackgroundImage, sectionImage, className, removeContainer = false } = props;

  const mainClassName = classNames(fluid ? "container-fluid" : "container", className);

  const style = sectionBackgroundImage && !sectionImage ? { backgroundImage: `url(${sectionBackgroundImage})` } : {};

  const nestedChildren = (
    <React.Fragment>
      {sectionImage && <div className="section-image">{sectionImage}</div>}
      {props.children}
    </React.Fragment>
  );

  const sectionContainer = removeContainer ? (
    <div className={classNames("section", sectionClassName, mainClassName)}>{props.children}</div>
  ) : (
    <section className={classNames("section", sectionClassName)} style={style}>
      {sectionImage && <div className="section-image">{sectionImage}</div>}
      <div className={mainClassName}>{props.children}</div>
    </section>
  );

  const divContainer = removeContainer ? <div className={sectionClassName}>{nestedChildren}</div> : <div className={classNames(sectionClassName, mainClassName)}>{nestedChildren}</div>;

  return section ? sectionContainer : divContainer;
};

export default Container;
